<!-- eslint-disable vue/no-v-html -->
<template>
	<b-card-group>
		<b-card>
			<h4>
				{{ translate('replicated_site') }}
			</h4>
			<is-loading
				:loading="loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
			<div v-if="!loading">
				<b-row>
					<b-col
						v-for="(replicatedLink, type) in replicatedLinks"
						:key="type"
						class="mb-3"
						cols="12">
						<replicated-link-selector
							:links="replicatedLink"
							:show-edit="false"
							:title="translate(`agency_${type}`)" />
					</b-col>
					<b-col cols="12">
						<b-alert
							show
							variant="info"
							v-html="translate('agency_discount_message')" />
					</b-col>
				</b-row>
			</div>
		</b-card>
	</b-card-group>
</template>
<script>

import { capitalize } from '@/config/Filters';
import WindowSizes from '@/mixins/WindowSizes';
import { distributor } from '@/settings/Roles';
import {
	Time as timeTranslations, Profile,
} from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import { YM_FORMAT, YMDHMS_FORMAT } from '@/settings/Dates';
import { TIMEZONE_NAME } from '@/settings/General';
import ReplicatedLinkSelector from '@/views/Dashboard/components/ReplicatedLinkSelector';

export default {
	name: 'AgencyReplicatedSite',
	components: { ReplicatedLinkSelector },
	messages: [timeTranslations, Profile],
	filters: {
		capitalize,
	},
	mixins: [WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			form: {},
			years: [],
			months: [],
			startDays: [],
			endDays: [],
			hours: [],
			minutes: [],
			distributor,
			replicatedSite: new ReplicatedSite(),
			modifyDiscount: new ReplicatedSite(),
			selectedStartYear: '',
			selectedStartMonth: '',
			selectedStartDay: '',
			selectedStartHour: '',
			selectedStartMinute: '',
			selectedEndYear: '',
			selectedEndMonth: '',
			selectedEndDay: '',
			selectedEndHour: '',
			selectedEndMinute: '',
			activeDiscount: false,
			YMDHMS_FORMAT,
			YM_FORMAT,
			TZN: TIMEZONE_NAME,
		};
	},
	computed: {
		response() {
			try {
				const { response } = this.replicatedSite.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		// activePromoData() {
		// 	try {
		// 		return this.response.promo;
		// 	} catch (error) {
		// 		return {};
		// 	}
		// },
		replicatedLinks() {
			try {
				return this.response.replicated_links || {};
			} catch (error) {
				return {};
			}
		},
		// activePromo() {
		// 	return Object.keys(this.activePromoData || {}).length > 0;
		// },
		loading() {
			try {
				return this.replicatedSite.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			try {
				return this.modifyDiscount.data.errors.errors;
			} catch (error) {
				return [];
			}
		},
		// start_date() {
		// 	if (this.selectedStartYear !== '' && this.selectedStartMonth !== '' && this.selectedStartDay !== '' && this.selectedStartHour !== '' && this.selectedStartMinute !== '') {
		// 		return this.createDate(this.selectedStartYear, this.selectedStartMonth, this.selectedStartDay, this.selectedStartHour, this.selectedStartMinute);
		// 	}
		// 	return '';
		// },
		// end_date() {
		// 	if (this.selectedEndYear !== '' && this.selectedEndMonth !== '' && this.selectedEndDay !== '' && this.selectedEndHour !== '' && this.selectedEndMinute !== '') {
		// 		return this.createDate(this.selectedEndYear, this.selectedEndMonth, this.selectedEndDay, this.selectedEndHour, this.selectedEndMinute);
		// 	}
		// 	return '';
		// },
	},
	watch: {
		// start_date(newVal) {
		// 	this.form.start_date = newVal;
		// 	this.clearError('start_date');
		// },
		// end_date(newVal) {
		// 	this.form.end_date = newVal;
		// 	if (typeof newVal === 'undefined') {
		// 		this.form.end_date = '';
		// 	}
		// 	this.clearError('end_date');
		// },
		// activeDiscount(active) {
		// 	if (!active && this.activePromo) {
		// 		this.alert.confirmation(
		// 			this.translate('disable_discounts'),
		// 			this.translate('disable_discounts_confirm'),
		// 			{
		// 				confirmButtonText: this.translate('confirm'),
		// 				cancelButtonText: this.translate('cancel'),
		// 			},
		// 			false,
		// 		).then(() => {
		// 			this.updatePromo();
		// 		}).catch(() => {
		// 			this.activeDiscount = true;
		// 		});
		// 	}
		// },
	},
	mounted() {
		this.getData();
		this.populateTime();
	},
	methods: {
		// resetData() {
		// 	this.form = {};
		// 	this.selectedStartYear = '';
		// 	this.selectedStartMonth = '';
		// 	this.selectedStartDay = '';
		// 	this.selectedStartHour = '';
		// 	this.selectedStartMinute = '';
		// 	this.selectedEndYear = '';
		// 	this.selectedEndMonth = '';
		// 	this.selectedEndDay = '';
		// 	this.selectedEndHour = '';
		// 	this.selectedEndMinute = '';
		// },
		getData() {
			this.replicatedSite.getAgencyReplicatedLinks();
			// .then((response) => {
			// 	if (!response) {
			// 		this.resetData();
			// 		return;
			// 	}
			// 	this.activeDiscount = Object.keys(response?.response.promo || {}).length > 0;
			// 	this.form.discount = response.response.promo.discount;
			// 	this.form.start_date = response.response.promo.from_date;
			// 	this.form.end_date = response.response.promo.to_date;
			//
			// 	let formatDate = this.dateFormat(response.response.promo.from_date);
			// 	this.selectedStartYear = formatDate.year;
			// 	this.selectedStartMonth = formatDate.month;
			// 	this.updateDays('start');
			// 	this.selectedStartDay = formatDate.day;
			// 	this.selectedStartHour = formatDate.hour;
			// 	this.selectedStartMinute = formatDate.minute;
			//
			// 	formatDate = this.dateFormat(response.response.promo.to_date);
			// 	this.selectedEndYear = formatDate.year;
			// 	this.selectedEndMonth = formatDate.month;
			// 	this.updateDays('end');
			// 	this.selectedEndDay = formatDate.day;
			// 	this.selectedEndHour = formatDate.hour;
			// 	this.selectedEndMinute = formatDate.minute;
			// });
		},
		// clearError(field) {
		// 	if (field) {
		// 		delete this.errors[field];
		// 	}
		// },
		// Dates
		// populateTime() {
		// 	const startYear = this.$moment(`${SCHEDULE_START_YEAR}`).year();
		// 	for (let i = 0; i <= 59; i += 1) {
		// 		if (i <= 20) this.years.push(startYear + i);
		// 		if (i < 10) {
		// 			this.hours.push(`0${i}`);
		// 			this.minutes.push(`0${i}`);
		// 			if (i > 0) this.months.push(`0${i}`);
		// 		} else {
		// 			if (i <= 23) this.hours.push(`${i}`);
		// 			if (i <= 12) this.months.push(`${i}`);
		// 			this.minutes.push(`${i}`);
		// 		}
		// 	}
		// },
		// updateDays(id) {
		// 	let year = '';
		// 	let month = '';
		//
		// 	const days = [];
		// 	if (id === 'start') {
		// 		this.startDays = [];
		// 		year = this.selectedStartYear;
		// 		month = this.selectedStartMonth;
		// 	} else if (id === 'end') {
		// 		this.endDays = [];
		// 		year = this.selectedEndYear;
		// 		month = this.selectedEndMonth;
		// 	}
		// 	const maxDaysInMonth = this.$moment(`${year}-${month}`, this.YM_FORMAT).daysInMonth();
		// 	for (let i = 1; i <= maxDaysInMonth; i += 1) {
		// 		if (i < 10) {
		// 			days.push(`0${i}`);
		// 		} else {
		// 			days.push(`${i}`);
		// 		}
		// 	}
		// 	if (id === 'start') {
		// 		this.startDays = days;
		// 		this.selectedStartDay = '01';
		// 	} else if (id === 'end') {
		// 		this.endDays = days;
		// 		this.selectedEndDay = '01';
		// 	}
		// },
		// createDate(year, month, day, hour, minute) {
		// 	if (year !== +'' && month !== +'' && day !== +'' && hour !== +'' && minute !== +'') {
		// 		return this.$moment(`${year}-${month}-${day} ${hour}:${minute}:00.000000`).format(this.YMDHMS_FORMAT);
		// 	}
		//
		// 	return '';
		// },
		// hasError(field) {
		// 	if (typeof this.errors[field] !== 'undefined') { return true; }
		// 	return false;
		// },
		// updatePromo() {
		// 	if (this.activeDiscount) {
		// 		this.modifyDiscount.updateAgencyPromo(this.form).then(() => {
		// 			this.getData();
		// 			this.alert.toast('success', this.translate('agency_discount_updated'));
		// 		});
		// 	} else {
		// 		this.modifyDiscount.deactivateAgencyPromo().then(() => {
		// 			this.getData();
		// 			this.alert.toast('success', this.translate('agency_discount_disabled'));
		// 		});
		// 	}
		// },
		// dateFormat(date) {
		// 	const formatDate = {};
		// 	formatDate.year = this.$moment(date).format('YYYY');
		// 	formatDate.month = this.$moment(date).format('MM');
		// 	formatDate.day = this.$moment(date).format('DD');
		// 	formatDate.hour = this.$moment(date).format('HH');
		// 	formatDate.minute = this.$moment(date).format('mm');
		// 	return formatDate;
		// },
	},
};
</script>
